import React from "react";
//import assets from '../components/assets';
import { ReactComponent as CasesNext } from "../assets/arrow-right.svg";
import { ReactComponent as CasesPrev } from "../assets/arrow-left.svg";
import one from "../assets/one.jpg";
import two from "../assets/two.jpg";
import three from "../assets/three.jpg";


//import assets from "../components/assets";
//import curologymin from "../components/assets/curologymin.png";

const caseStudies = [
 {
    id: 1,
    subtitle: "ಇತ್ತೀಚಿನ ವ್ಯಾಪ್ತಿ",
    title: "ನಿಮ್ಮ ಪ್ರದೇಶದಲ್ಲಿ ಇತ್ತೀಚಿನ ಸುದ್ದಿ. ನವೀಕೃತವಾಗಿರಿ",
    img: "one"
  },
  {
    id: 2,
    subtitle: "ನಿಮ್ಮ ಅನುಕೂಲಕ್ಕೆ ತಕ್ಕ ಹಾಗೆ",
    title: "ಸುದ್ದಿ 24 ಗಂಟೆಗಳ ಕಾಲ ಲಭ್ಯವಿದೆ.",
    img: "two"
  },
  {
    id: 3,
    subtitle: "News App",
    title: "ನಮ್ಮ ಅಪ್ಲಿಕೇಶನ್‌ನೊಂದಿಗೆ ಪ್ರಯಾಣದಲ್ಲಿರುವಾಗ ನವೀಕರಿಸಿ",
    img: "three"
  }
];

const Cases = () => {
  return (
    <section className='cases'>
      <div className='container-fluid'>
        <div className='cases-navigation'>
          <div className='cases-arrow prev disabled'>
            <CasesPrev />
          </div>
          <div className='cases-arrow next'>
            <CasesNext />
          </div>
        </div>
        <div className='row'>
          {caseStudies.map(caseItem => (
            <div className='case' key={caseItem.id}>
              <div className='case-details'>
                <span>{caseItem.subtitle}</span>
                 
                <h2>{caseItem.title}</h2>
                 
              </div>
              <div className='case-image'>
             
                <img
                //src={require(`${caseItem.img}`)} alt="product"
                 //key={caseItem.id}
                 //src={caseItem.src}
                 src={require(`../assets/${caseItem.img}.jpg`).default}
                  //src={one}
                 //alt={caseItem.title}
                />
                
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Cases;
