import React from "react";

const About = () => {
  return (
    <div className='page'>
      <div className='container'>
        <div className='row'>
          <h3>This is the About page</h3>
        </div>
      </div>
    </div>
  );
};

export default About;
