import React from "react";
import VideoPlayer from "react-video-js-player";
import gp3 from "../assets/gp3.png";
import news from "../assets/news.mp4";




const CaseStudies = () => {


  return (



  <div className='page1'>
    
      <div className='container'>  
      <video  autoPlay loop muted
      style={{
      position:"absolute",
      width:"100%",
      left:"50%",
      top:"50%",
      height:"100%",
      objectFit:"cover",
      transform:"translate(-50%,-50%)",
      zIndex:"0"
      }}
      
      >
     <source src={news} type="video/mp4"/>
    
   </video>
      
      <div className='row'>
 
        <h2 style={{zIndex:"1"}}>
ಪ್ರಯಾಣದಲ್ಲಿರುವಾಗ 24/7 ಅಪ್‌ಡೇಟ್ ಆಗಿರಲು ನಮ್ಮ ಸುದ್ದಿ ಅಪ್ಲಿಕೇಶನ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ</h2>
   <h2 style={{zIndex:"1"}}>
ಇತ್ತೀಚಿನ ಸುದ್ದಿಗಳನ್ನು ತಕ್ಷಣವೇ ನವೀಕರಿಸಲಾಗಿದೆ.</h2>
  <h2 style={{zIndex:"1"}}>ಅದನ್ನು google play ನಲ್ಲಿ ಪಡೆಯಿರಿ</h2>
       <br />
    <a href="https://play.google.com/store"  style={{zIndex:"1"}}>
       <img  className='img1' src={gp3} alt=""
        style={{zIndex:"1"}} />
      </a>
 
      </div>
      </div>
      </div>
    
 
    
   
  );
 
};

export default CaseStudies;


