import React from 'react';
import {Link} from 'react-router-dom';
import { ReactComponent as RightArrow } from "../assets/arrow-right.svg";

const Banner = () => {
  return (
    <section className='main'>
      <div className='container'>
        <div className='row'>
          <h2>
          <br />
            <div className='line'>
              <span>ಹುಬ್ಬಳ್ಳಿಯಲ್ಲಿ ಇವತ್ತು ಏನಾಗಿದೆ ? </span>
            </div>
            <br />
            <div className='line'>
              <span>ನಿರೀಕ್ಷಿಸಿ !! ನಾವು ನಿಮಗೆ ಹೇಳುತ್ತೇವೆ. </span>
            </div>
          </h2>
          <div className='btn-row'>
            <Link to='/downloadapp'>
               Download App <RightArrow />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
