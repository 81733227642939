import React from "react";

const Approach = () => {
  return (
    <div className='page3'>
 

      <div className='container'>
        <div className='row'>
        <h1>Services<br />ನಮ್ಮೊಂದಿಗೆ ಸಂಪರ್ಕ ಸಾಧಿಸಿ.</h1>
          <h3>ಕಥೆ ಸಿಕ್ಕಿತೇ? ಅದನ್ನು ನಮಗೆ ಕಳುಹಿಸಿ ಎಲ್ಲರೂ ಕೇಳುವಂತೆ ನಾವು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುತ್ತೇವೆ! </h3>
           <h3>ಇತರ ಜನರೊಂದಿಗೆ ಸಂಪರ್ಕ ಹೊಂದಲು ಬಯಸುವಿರಾ? ನಿಮ್ಮ ಜಾಹೀರಾತನ್ನು ನಮ್ಮೊಂದಿಗೆ ಪೋಸ್ಟ್ ಮಾಡಿ ಮತ್ತು ಬೆಳೆಯಿರಿ!<br /><br/>ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು ಬಯಸುವಿರಾ? ನಮ್ಮೊಂದಿಗೆ ಸಂಪರ್ಕ ಸಾಧಿಸಿ.</h3>
     
       </div>
      </div>
    </div>
  );
};

export default Approach;
